<template>
  
    <router-view></router-view>
  
</template>

<script>
export default {
  name: 'App',
  data () {
    return {

    }
  },
  watch: {
    windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
    }
  },
  mounted () {
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {      
        this.$store.commit('updateWindowWidth', document.documentElement.clientHeight)
        this.$store.commit('updateWindowHeight', document.documentElement.clientWidth)
      })()
    };
    this.$router.push("/login")
    
  },
  computed: {
    windowWidth () {
      return this.$store.state.tab.windowWidth
    },
    windowHeight () {
      return this.$store.state.tab.windowHeight
    }
  }
}
</script>

<style lang='scss' scoped>
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
</style>