import Vue from 'vue'
import App from './App.vue'
import http from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../src/assets/fonts/iconfont.css'
import router from '../router'
import store from '../store'

Vue.use(ElementUI);

Vue.config.productionTip = false

Vue.prototype.$http = http

router.beforeEach((to, from, next) => {
  store.commit('getToken')
  const token = store.state.user.token
  if(!token && to.name !== 'login') {
    next({name: 'login'})
  } 
  else if(token && to.name === 'login') {
    console.log(from, to)
    store.commit('getUSRName')
    const usrname = store.state.user.usrname
    console.log(usrname)
    store.commit('getUSRPsw')
    const psw = store.state.user.psw
    console.log(psw)
    store.commit('getCompany')
    const company = store.state.user.company
    console.log(company)
    store.commit('getTitleList')
    const titlelist = store.state.user.titlelist
    console.log(titlelist)
    next({name: 'Main'})
  } 
  else 
  {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
