import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/Main.vue'),
    children: [
      {
        path: "/curve",
        name: 'curve',
        component: () => import('../views/curve/curve.vue')
      },
      {
        path: "/statistic",
        name: 'statistic',
        component: () => import('../views/statistic/statistic.vue')
      },
      {
        path: "/historyalarm",
        name: 'historyalarm',
        component: () => import('../views/historyalarm/historyalarm.vue')
      },
      {
        path: "/program",
        name: 'program',
        component: () => import('../views/program/program.vue')
      },
      {
        path: "/detail",
        name: 'detail',
        component: () => import('../views/detail/detail.vue')
      },
      {
        path: "/normal",
        name: 'normal',
        component: () => import('../views/normal/normal.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router