import Cookie from 'js-cookie'
export default {
  state: {
    domain: 'https://www.iclimate.top:8888/',
    domain443: 'https://www.iclimate.top/',
    total: 0,
    loaded: 0,
    windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
    windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
    titleno: ''
  },
  mutations: {
    updateProgress (state, val) {
      state.loaded = val.loaded
      state.total = val.total
    },
    updateWindowWidth (state, val) {
      state.windowWidth = val
    },
    updateWindowHeight (state, val) {
      state.windowHeight = val
    },
    updateTitleNo(state, val) {
      state.titleno = val
      Cookie.set(('titleno', val))
    },
    clearTitleNo(state) {
      state.titleno = ''
      Cookie.remove('titleno')
    },
    getTitleNo(state) {
      state.titleno = state.titleno || Cookie.get('titleno')
  },
  }
}