import Cookie from 'js-cookie'
export default {
    state: {
        token: '',
        usrname: '',
        psw: '',
        company: '',
        titlelist: []
    },
    mutations: {
        setToken(state, val) {
            console.log('token', val)
            state.token = val
            Cookie.set('token', val)
        },
        setUSRName(state, val) {
            console.log('usrname', val)
            state.usrname = val
            Cookie.set('usrname', val)
        },
        setUSRPsw(state, val) {
            console.log('psw', val)
            state.psw = val
            Cookie.set('psw', val)
        },
        setCompany(state, val) {
            console.log('company', val)
            state.company = val
            Cookie.set('company', val)
        },
        setTitleList(state, val) {
            console.log('titlelist', val)
            state.titlelist = val
            Cookie.set('titlelist', val)
        },
        clearToken(state) {
            state.token = ''
            Cookie.remove('token')
        },
        clearUSRName(state) {
            state.usrname = ''
            Cookie.remove('usrname')
        },
        clearUSRPsw(state) {
            state.psw = ''
            Cookie.remove('psw')
        },
        clearCompany(state) {
            state.company = ''
            Cookie.remove('company')
        },
        clearTitleList(state) {
            state.titlelist = []
            Cookie.remove('titlelist')
        },
        getToken(state) {
            state.token = state.token || Cookie.get('token')
        },
        getUSRName(state) {
            state.usrname = state.usrname || Cookie.get('usrname')
        },
        getUSRPsw(state) {
            state.psw = state.psw || Cookie.get('psw')
        },
        getCompany(state) {
            state.company = state.company || Cookie.get('company')
        },
        getTitleList(state) {
            state.titlelist = Cookie.get('titlelist')
        },
    }
}